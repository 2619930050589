import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex-grow flex-col grid grid-cols-1 sm:grid-cols-10 items-center" }
const _hoisted_2 = { class: "sm:col-span-5 p-4 pt-0 pb-8" }
const _hoisted_3 = {
  key: 0,
  class: "question-label question-label-project2"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScreenWrapper = _resolveComponent("ScreenWrapper")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["screen-content", _ctx.config.wrapperClasses])
  }, [
    _createVNode(_component_ScreenWrapper, {
      "class-name": "pt-0",
      next: _ctx.next,
      screen: _ctx.screen,
      "hide-arrow": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.config.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _withDirectives(_createElementVNode("span", null, null, 512), [
                    [_directive_t, _ctx.config.title]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.config.textPart1)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 1,
                  class: "",
                  innerHTML: _ctx.$t(_ctx.config.textPart1, {name: _ctx.state.nickname.value})
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.config.textPart2)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 2,
                  class: "mt-4",
                  innerHTML: _ctx.$t(_ctx.config.textPart2, {name: _ctx.state.nickname.value})
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true),
            (_ctx.config.textPart3)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 3,
                  class: "mt-4",
                  innerHTML: _ctx.$t(_ctx.config.textPart3, {name: _ctx.state.nickname.value})
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true),
            (_ctx.config.textPart4)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 4,
                  class: "mt-4",
                  innerHTML: _ctx.$t(_ctx.config.textPart4, {name: _ctx.state.nickname.value})
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true)
          ]),
          (_ctx.config.bgImage)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "col-span-5 h-full flex-col items-center flex",
                style: _normalizeStyle(`background-image: ${_ctx.config.bgImage}; background-size: cover; background-repeat: no-repeat; background-position: center; ${_ctx.config.bgStyles}`)
              }, null, 4))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["next", "screen"])
  ], 2))
}