
import {defineComponent} from 'vue';
import {useState} from "@/hooks/state";

const state = useState();

export default defineComponent({
  name: 'ActionButton',
  emits: ['click'],
  props: {
    text: {
      type: String,
      default: ''
    },
    rawtext: {
      type: String,
      default: ''
    },
    colorClass: {
      type: String,
      default: ''
    },
    buttonClass: {
      type: String,
      default: 'action-button'
    },
    selected: {
      type: Boolean,
      default: false
    },
    preicon: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      state
    }
  },

  methods: {
    click() {
      this.$emit('click')
    }
  }
});
