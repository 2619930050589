
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenConfigOptionsAnswer} from "@/Screens.types";

const state = useState();
export default defineComponent({
  components: {
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenConfigOptionsAnswer,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false,
      showQuestion: false,
      correct: state.answers.value?.[this.config.answerKey] == this.config.correctAnswer?.[0] && this.config.correctAnswer?.[0],
    }
  },

  mounted() {

  },

  methods: {
    next() {
      this.goTo(this.config.screenNext)
    },back() {
      this.goTo(-1)
    },
    start() {
      // Open modal for nicname
      this.showNicknameModal = true;
    },
  }
});
