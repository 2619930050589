
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenCountriesOptions} from "@/Screens.types";
import Countries from "@hotosm/iso-countries-languages"

const state = useState();
export default defineComponent({
  components: {
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenCountriesOptions
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false
    }
  },

  mounted() {

  },

  computed: {
    countries() {
      return Countries.getCountries('en')
    },

    selected() {
      return this.state.answers.value?.[this.config.answerKey] ?? this.config.selected;
    }
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext);
    },
    start() {
      // Open modal for nicname
      this.showNicknameModal = true;
    },
    answer(answer: string) {
      state.setAnswer(this.config.answerKey, answer, {
        [this.config.answerKey]: this.$t(this.config.question)
      });
      this.next();
    }
  }
});
