
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import StarRating from 'vue-star-rating';
import {ScreenVideo} from "@/Screens.types";


const state = useState();
export default defineComponent({
  components: {
    ScreenWrapper,
    StarRating
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenVideo
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false,
      showQuestion: false,
      response: '',
    }
  },

  mounted() {
    // setTimeout(() => {
    //     this.$root.setupAndPlayIfOn(this.FILES.audio_welcome)
    // }, 1000)
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext);
    },
    start() {
      // Open modal for nicname
      this.showNicknameModal = true;
    },
    answer(answer: string | number) {
      state.setAnswer(this.config.answerKey, answer, {
        [this.config.answerKey]: 'Rate Us'
      });
      // this.next();
    }
  }
});
