export enum ScreensEnum {
    SCIRT_INTRO = 'scirt-intro',
    LastShopping = 'LastShopping',
    HowMuchSpendOnJeans = 'HowMuchSpendOnJeans',

    WasteSlides = 'WasteSlides',

    BringBackScreen = 'BringBackScreen',
    VideoScreen = 'VideoScreen',
    FinalScreen = 'FinalScreen',
    BuyingCriteria = 'BuyingCriteria',
    WhatToPayForPants = 'WhatToPayForPants',
    HowMuchSwimmwear = 'HowMuchSwimmwear',
    InitalQuestionsThankYou = 'InitalQuestionsThankYou',
    AgeGroup = 'AgeGroup',
    Country = 'Country',
    Gender = 'Gender',
    WaterNeededForShirtQuestion = 'WaterNeededForShirtQuestion',
    WaterNeededForShirtAns = 'WaterNeededForShirtAns',
    Emissions = 'Emissions',
    EmissionsAns = 'EmissionsAns',
    HowManyPeopleEmployed = 'HowManyPeopleEmployed',
    HowManyPeopleEmployedAns = 'HowManyPeopleEmployedAns',
    WhereDoYouBuyClothes = 'WhereDoYouBuyClothes',
    ShoppingHabits = 'ShoppingHabits',
    HowManyProduced = 'HowManyProduced',
    HowManyProducedAns = 'HowManyProducedAns',
    HowOftenConsiderSustainability = 'HowOftenConsiderSustainability',
    HowYouCompare = 'HowYouCompare',
    DragDropShirt = 'DragDropShirt',
    NotSoldGarments = 'NotSoldGarments',
    NotSoldGarmentsAns = 'NotSoldGarmentsAns',
    HowMuchClothesBoughtKg = 'HowMuchClothesBoughtKg',
    HowMuchClothesBoughtKgAns = 'HowMuchClothesBoughtKgAns',
    HowManyTShirts = 'HowManyTShirts',
    HowManyTShirtsAns = 'HowManyTShirtsAns',
    Statistics = 'Statistics',
    ShareIdeasTexts = 'ShareIdeasTexts',
    ShareIdeasQuestion  = 'ShareIdeasQuestion',
    RateUs = 'RateUs',
}


export enum ScreensTypeEnum {
    SCREEN_INTRO = 'screen-intro',
    SCREEN_OPTIONS_GREEN = 'screen-options-green',
    SCREEN_OPTIONS_GREEN_TOGGLE_QUESTION = 'screen-options-green-toggle-question',
    SCREEN_ANSWER_GREEN = 'screen-answer-green',
    SCREEN_ANSWER_GREEN_WITH_HIGH_LOW = 'screen-answer-green-with-high-low',
    SCREEN_OPTIONS = 'screen-options',
    SCREEN_ANSWER = 'screen-answer',
    SCREEN_ANSWERS_TWO_OPTIONS = 'screen-answers-two-options',
    SCREEN_OPTIONS_COUNTRIES = 'screen-options-countries',
    SCREEN_OPTIONS_BUTTONS = 'screen-options-buttons',
    SCREEN_OPTIONS_CIRCLES = 'screen-options-circles',
    SCREEN_DEMOGRAPHICS_CHART = 'screen-demographics-chart',
    SCREEN_OPTIONS_DRAGDROP = 'screen-options-dragdrop',
    SCREEN_WASTE_CAROUSEL = 'screen-waste-carousel',
    SCREEN_BEFORE_SHARE = 'screen-before-share',
    SCREEN_BRING_BACK = 'screen-bring-back',
    SCREEN_VIDEO = 'screen-video',
    SCREEN_FINAL = 'screen-final',
    SCREEN_SLIDER_PINK = 'screen-slider-pink',
    SCREEN_QUESTION_TWO_BUTTONS = 'screen-question-two-buttons',
    SCREEN_SORT_OPTIONS = 'screen-sort-options',
    SCREEN_TEXTS = 'screen-tree-texts',
    SCREEN_SLIDER_PINK_MULTI = 'screen-slider-pink-multi',
    SCREEN_OPTIONS_RADIO_BUTTONS = 'screen-options-radio-buttons',
    SCREEN_SINGLE_QUESTION = 'screen-single-question',
    SCREEN_RATE_US = 'screen-rate-us',
}
