
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenConfigIntro} from "@/Screens.types";

const state = useState();
export default defineComponent({
  components: {
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenConfigIntro,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false,
    }
  },

  mounted() {
    this.state.resetState();
  },

  computed: {
    locale() {
      console.log(this.$root.$i18n)
      return this.locales.find(locale => locale.val === this.$root.$i18n.locale)
    },
    locales() {
      return {
        en: this.$t('locales.en'),
        es: this.$t('locales.es'),
        de: this.$t('locales.de'),
      };
    },
    localeCountries() {
      return {en: 'gb', de: 'de', es: 'es'}
    }
  },

  methods: {
    next() {
      if (!this.state.nickname.value) {
        this.state.setNickname(this.$t('defaultNickname') as string)
      }

      this.goTo(this.config.screenNext)
    },

    start() {
      // Open modal for nicname
      this.showNicknameModal = true;
    },

    selectLocale(locale) {
      console.log('Switching locale to: ', locale);

      const urlQuery = new URLSearchParams(window.location.search);
      urlQuery.set('locale', locale);

      window.location.search = urlQuery.toString();
      // this.$root.$i18n.locale = locale;
    }
  }
});
