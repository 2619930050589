import {ScreensEnum} from "@/Screens.enum";

export default {
    "screen": {
        "welcome": "Welcome!",
        "lets-start": "Let's start...",
        "devices": "Try to guess",
        "video": "What are REEs?",
        "map": "Who owns REEs?",
        "alternative-supply": "Our alternatives",
        "get-magnets": "The magnets Lab",
        "magnets-structure": "Making alloys",
        "magnets-process": "Final step",
        "close-loop": "Close the loop!",
        "challenges": "Three challenges",
        "feedback": "Your feedback!",
        "action-time": "Action time",
        "guess": "Can you guess which of these products contain Rare Earths alloys / magnets?"
    },
    "eu-footer": 'This project has received funding from the Horizon 2020 Programme under grant agreement n°101003906. <br> The content of this website reflects only the author\'s view. The European Commission is not responsible for any use that may be made of the information it contains.',
    "buttons": {
        "submit": "Submit",
        "yes": "YES",
        "no": "NO",
        'next': 'Next',
        'back': 'Back',
        "start": "Let's start {name}!",
        "go": "Let's go {name}!",
        "see": "Let's see",
        "letsfindout": "Let's find out!",
    },
    'texts': {
        'or': 'or',
        'and': 'and',
        'readmore': 'Read more',
        'readless': 'Read less',
    },

    locales: {
        en: 'English',
        de: 'Deutsch',
        es: 'Español',
    },

    'defaultNickname': 'Visitor',

    "intro": {
        "nickname": "Nickname",
        "par1": `Welcome to the SCIRT interactive experience! This tool is designed
to <b>foster engagement</b> while shedding light on the fashion industry's impact on the environment and society. Your valuable input will help shape insights, raise awareness, drive change in the textile industry and consumer behaviour.`,
        "par3": "Ready to start? Enter your (nick)name and click 'Start' now!",
        "beforeButton": 'Your data remains anonymous, used solely for research purposes. <br> ONLY SCIRT consortium, and researchers will have access',
    },

    [ScreensEnum.BringBackScreen]: {
        'question': 'What would encourage you to bring your clothes back to the shop or brand where you bought it, so they can be recycled into new clothes?',
        'placeholder': 'Please write your answer here',
        'button': 'Send and next',
    },
    [ScreensEnum.VideoScreen]: {
        'text1': 'Thank you for your ideas! We will take them onboard. Congrats, you have now completed every step of this online experience! Before you go, we would kindly ask you to rate this short journey. Thank you!',
        'text2': 'Are you interested to stay in the loop? Follow the SCIRT project on social media and be part of our awareness campaign.',
        'text3': 'We also invite you to sign up for the SCIRT newsletter.',
        'button': 'Finish!',
    },

    [ScreensEnum.FinalScreen]: {
        'text': "Thank you for your ideas! We will take them onboard. If you are not ready to part ways yet, you are kindly invited to stay a few minutes longer and help us bring a more sustainable approach to the fashion system by filling in a short survey about your fashion shopping habits.",
        'survey': 'Take the survey',
        'surveyLink': 'https://prospex.typeform.com/to/YJAYJLwi',
    },
    [ScreensEnum.LastShopping]: {
        'text1': 'Ok, {name}, let\'s start with the topic of buying clothes.',
        'text2': "Take a moment to recall your last clothes shopping experience. Visualize the items you purchased. Got the memory? Excellent! Let's proceed.",
        'question': 'Now please share with us, {name}, why did you buy the clothes...',
        'answer1': 'Because you needed it.',
        'answer2': 'Because you liked the design (but didn\'t really need them).',
    },
    [ScreensEnum.BuyingCriteria]: {
        'text1': 'We appreciate your answer! Now, let\'s move on to the next question:',
        'question': `"When buying clothes, what criteria do you consider most important? Please prioritize by moving the boxes into a list starting with the most to least important."`,
        'hint': 'Drag to reorder',
        'price': 'Price',
        'quality': 'Quality (if it is long-lasting)',
        'esthetics': 'Esthetics',
        'ecofriendly': 'Eco-friendliness',
        'material': 'Material (natural over synthetic)',
        'other': 'Other / write your answer here?',
        'fairAndHumane': 'Fair and Humane production',
    },
    [ScreensEnum.HowMuchSpendOnJeans]: {
        'text1': 'Thank you, {name}! Now let\'s get a bit more specific and talk about prices:',
        'question': 'How much do you spend on a pair of jeans?',
    },
    [ScreensEnum.WhatToPayForPants]: {
        'question': 'What would you be willing to pay for a pair of office trousers?',
    },
    [ScreensEnum.HowMuchSwimmwear]: {
        'question': 'How much would you be willing to spend for swimwear on average?',
    },
    [ScreensEnum.InitalQuestionsThankYou]: {
        'text1': "Wow, thanks {name} already for the great input!",
        'text11': "By sharing your responses, you help us understand differences among EU countries, exploring potential links between demographics and consumer behavior.",
        'text2': 'Please rest assured that all your answers will be treated with utmost confidentiality and anonymity.',
        'text3': '<b>Now, let\'s get to know you better</b>. Please take a moment to answer the following demographic questions.'
    },
    [ScreensEnum.AgeGroup]: {
        'question': '1. Which age group are you?',
        'below-18': 'Below 18',
        '18-30': '18 - 30',
        '30-40': '30 - 40',
        '40-50': '40 - 50',
        '50-60': '50 - 60',
        '60-70': '60 - 70',
        'above-70': 'Above 70',
    },
    [ScreensEnum.Country]: {
        'question': '2. Where do you live?',
    },
    [ScreensEnum.Gender]: {
        'question': '3. What is your gender?',
        'female': 'Female',
        'male': 'Male',
        'non-binary': 'Non-binary',
        'not-say': 'Prefer not to answer',
    },
    ['WaterNeededForShirt']: {
        'text1': "Thank you for your valuable input! Your responses have already greatly contributed to our research.",
        'text2': 'Now, let\'s dive into some <b>interesting facts about the fashion industry</b>. Are you ready, {name}!?',
        'question': 'Do you know how much water is needed to produce one T-shirt?',
        'ans_ok': 'Spot on {name}!',
        'ans_nok': 'Oops, an underestimation this time!',
        'ans_text': 'This is how much water is needed for just one cotton T-shirt.',
        'ans_nok_text': 'This is how much water is needed for just one cotton T-shirt.',
        'fact': 'To put it in a perspective, this amount of water would be enough drinking water for one person for 2.5 years!'
    },
    ['Emissions']: {
        'question': 'Curious about the fashion industry\'s impact on global CO2 emissions? <b>How much of the global CO2 emission is the fashion industry responsible for?</b> Let\'s find out!',
        'ans_ok': 'Absolutely correct! You\'ve got it spot on!',
        'ans_ok_text': 'The fashion industry accounts for approximately 2% - 5% of global carbon emissions, surpassing the combined emissions of the aviation and shipping industries! Impressive knowledge!',
        'ans_nok': 'It is actually less...',
        'ans_nok_text': "The fashion industry creates around 2% - 5% of the global carbon emissions, which is more than the aviation industry and shipping combined!",
        'source': 'Source: https://www.wri.org/research/roadmap-net-zero-delivering-science-based-targets-apparel-sector',
    },
    [ScreensEnum.HowManyPeopleEmployed]: {
        'question': 'How many people does the clothing industry employ globally?',
        '100mil': '100 million',
        '200mil': '200 million',
        '300mil': '300 million',
        '400mil': '400 million',
        'ans_ok_title': 'Spot on!',
        'ans_nok_title': 'Oops not quite!',
        'ans_ok_text': 'The global fashion industry employs approximately 300 million people, with a wide range of salary levels, depending on experience and location.',
        'ans_nok_text': 'The fashion industry employs around 300 million people, with a wide range of salaries',
        'source': "MacArthur, E. (2017). Foundation A New Textiles Economy: Redesigning Fashion’s Future. London, UK.",
    },
    [ScreensEnum.WhereDoYouBuyClothes]: {
        'text1': 'Now that we\'ve explored some aspects of the fashion industry, let\'s get back to your own preferences.',
        'question': 'Where do you normally buy your clothes?',
        'physical': "Only offline <br> (Physical shops)",
        'online': 'Only online',
    },
    [ScreensEnum.ShoppingHabits]: {
        'intro': 'By using this structured Likert scale, we can better understand your clothing preferences and habits. Feel free to provide your honest responses!',
        'question1': "How often do you buy clothes from well-known brands like Zara, H&M, GAP, Primark, Shein, Uniqlo, etc.?",
        'question2': "How often do you buy clothes from more sustainable brands like HNST, Organic Basics, Reformation, etc.?",
        'question3': "How often do you shop from small or local designers?",
        'question4': "How often do you buy second-hand clothes?",
        'question5': "Do you make your own clothes?",
        'never': 'Never',
        'rarely': 'Rarely',
        'sometimes': 'Sometimes',
        'often': 'Often',
        'always': 'Always',
        'additionalOptionsText': 'Feel free to provide additional insights here',
    },
    [ScreensEnum.HowManyProduced]: {
        'intro': '<b>Back to the fascinating facts...</b>',
        'question': 'Can you take a guess at how many fashion garments are produced globally each year by the fashion industry?',
        '500-600mil': '500 - 600 million',
        '1-15bil': '1 - 1.5 billion',
        '50-60bil': '50 - 60 billion',
        '100-150bil': '100 - 150 billion',
        'ans_ok_title': 'Correct {name}!',
        'ans_nok_title': 'It\'s much more.',
        'ans_ok_text': 'The estimated number is 100 - 150 billion items per year!',
        'ans_nok_text': 'The estimated number is 100 - 150 billion items per year!',
        'source': "Rudenko, O. (2018). The 2018 apparel industry overproduction report and infographic. Share Cloth",
    },
    [ScreensEnum.HowOftenConsiderSustainability]: {
        'question': 'Now we\'re curious about something else, generally how often do you consider sustainable practices in your daily life?',
        'idont': 'I don\'t consider it at all',
        'rarely': 'I rarely consider it',
        'somewhat': 'I somewhat consider it',
        'often': 'I often consider it',
        'always': 'I am doing everything I can to be sustainable',
    },
    [ScreensEnum.HowYouCompare]: {
        'text1': 'Thank you, {name}! Your insights are valuable in providing a broader understanding of sustainable practices.',
        'text2': 'Now, let\'s see how you compare to your peers in the same age as those who participated in this questionnaire.',
        'chart-label': 'Age group: {AgeGroup}, gender: {Gender}',
    },
    [ScreensEnum.DragDropShirt]: {
        'question': 'Let\'s take a moment to reflect on a t-shirt or other clothing piece that you own but no longer wear. What is your typical course of action with such items?',
        'dragShirtText': "Drag and drop <br> the t-shirt in the right box.",
        'secondhand': 'Selling second-hand',
        'garbage': 'Garbage bin',
        'gift': 'Gift for a friend / family member',
        'reuse': "Reuse (making something else out of it)",
        'keep': 'Keep in the wardrobe for a long time',
        'container': 'Textile container',
        'charity': 'Charity donation',
        'bringback': "Bringing back to the shop where you bought it",
        'other': 'Other solution',
        'other-text': 'Write your ideas here',
    },
    [ScreensEnum.NotSoldGarments]: {
        'intro': 'The 100-150 billion garments produced each year results in significant overall waste.',
        'question': 'Let\'s explore what happens to these clothes once they are manufactured. <b>Can you guess how much of the produced garments never gets sold in the first place? </b>',
        '10-percent': '10 %',
        '20-percent': '20 %',
        '30-percent': '30 %',
        '40-percent': '40 %',
        'ans_ok_title': 'You\'re right {name}!',
        'ans_nok_title': 'Not quite.',
        'ans_ok_text': 'It is estimated that around 30 % of all clothes produced is never sold. This means that there is significant overproduction in the fashion industry.',
        'ans_nok_text': 'It is estimated that around 30 % of all clothes produced is never sold. This means that there is significant overproduction in the fashion industry.',
        'source': 'Australian Circular Textile Association (ACTA), (2019).',
    },
    [ScreensEnum.HowMuchClothesBoughtKg]: {
        'question': 'However, the majority of clothes still ends up in the wardrobes of consumers. Can you guess how many clothes, footwear and household textiles, the average European consumer buy every year? Share your estimation with us!',
        'ans_ok_title': 'Spot on!',
        'ans_nok_title': 'Close, but not quite.',
        'ans_ok_text': 'The average European consumes 26 kg of textiles a year!',
        'ans_nok_text': 'It\'s more than you think. The average European consumes 26 kg of textiles a year!',
        '5kg': 'ca 5 kg',
        '9kg': 'ca 9 kg',
        '15kg': 'ca 15 kg',
        '26kg': 'ca 26 kg',
        'source': "Manshoven, S., Christis, M., Vercalsteren, A., Arnold, M., Nicolau, M., Lafond, E., ... & Coscieme, L. (2019). Textiles and the environment in a circular economy. Eur Top Cent Waste Mater a Green Econ, 1-60.",
    },
    [ScreensEnum.HowManyTShirts]: {
        'question': "EU consumers not only buy clothes extensively but also discard a significant amount, on average 11kg of textiles per person per year is being discarded. Can you take a guess at how many T-shirts this translates to?",
        '20-shirts': '20 T-shirts',
        '40-shirts': '40 T-shirts',
        '60-shirts': '60 T-shirts',
        '75-shirts': '75 T-shirts',
        'ans_ok_title': 'Full points!',
        'ans_nok_title': 'Think more.',
        'ans_ok_text': 'This translates to roughly 75 T-shirts. It is the same as if you throw away one T-shirt every five days.',
        'ans_nok_text': 'This translates to roughly 75 T-shirts. It is the same as if you throw away one T-shirt every five days.',
        'source': "Beasley, J., Georgeson, R., Arditi, S., & Barczak, P. (2014). Advancing Resource Efficiency in Europe: Indicators and waste policy scenarios to deliver a resource efficient and sustainable Europe. Brussels: European Environmental Bureau (EEB).",
    },
    [ScreensEnum.WasteSlides]: {
        'text1': 'Thank you for your answers, we really appreciate your input!',
        'text2': 'Now let\'s explore the typical fate of textile waste in Europe. Out of the 5.8 million tons of textile waste produced in Europe annually, the estimated breakdown is as follows:',
        'source': "Manshoven, S., Christis, M., Vercalsteren, A., Arnold, M., Nicolau, M., Lafond, E., ... & Coscieme,\nL. (2019). Textiles and the environment in a circular economy. Eur Top Cent Waste Mater a\nGreen Econ, 1-60",

        'readMoreModal_title': 'Global <br> perspective',
        'readMoreModal_slide0_text1': 'The world produces 92 million tons of textile waste every year.',
        'readMoreModal_slide0_text2': 'Clothing and textiles currently make up at least 7% of the total amount of waste in global landfill space.',
        'readMoreModal_slide0_text3': 'Textile production generates 42 million tons of plastic waste per year, making the textile industry the second-highest industrial sector after packaging.',
        'readMoreModal_slide0_text4': 'Every time a synthetic garment is washed, it releases tiny plastic microfibres into the water. Up to 500,000 tons of microfibres end up in the ocean every year.',
        'readMoreModal_slide0_text5': '87% of the materials and fibres used to make clothing will end up in either incinerators or landfills.',
        'readMoreModal_slide1_text1': 'Clothing is being made and thrown out at an alarming rate. Because of this, a pile of clothes is now visible from Space. The huge garment pile is growing by about 39,000 tons a year.',
        'readMoreModal_sources_slide0': 'Source: UNEP, Global Fashion Agenda, Ellen MacArthur Foundation, Science Advances.',
        'readMoreModal_sources_slide1': 'Source: High fashion! Mountain of discarded clothes in Chilean desert is visible from space (satellite photo), by Robert Lea, published June 22, 2023'
    },
    [ScreensEnum.Statistics]: {
        'text1': "These statistics shed light on the improved recycling and sustainability practices in the fashion industry.",
        'text2': 'The fact that <b>less than 1% of textile waste is recycled</b> into new clothes highlights the <b>pressing need for more improvement</b> in this area.',
        'text3': 'There is undoubtedly a big need to address the textile waste practices.',
        'text4': '<b>So what can be done to improve the situation?</b>',
        'button': 'Let\'s find out!',
    },
    [ScreensEnum.ShareIdeasTexts]: {
        'text1': "To make progress and improve the situation, we have several vital steps to take. Luckily, there are already many actors working towards positive change.",
        'text2': 'For example, the currently running <b>EU-funded project SCIRT is actively developing new ways to create and sell clothes from recycled materials.</b> The goal is to close the loop and achieve a fully circular fashion system by collecting and utilizing old garments to produce new ones.',
        'text3': '<b>By participating in this online experience, you are already contributing to these advancements by becoming more aware of today\'s fashion system.</b>',
        'text4': 'Before we conclude, we have one more request. Please share your ideas with us!',
    },
    [ScreensEnum.ShareIdeasQuestion]: {
        'question': 'What would encourage you to bring your clothes back to the shop or brand where you bought it, so they can be recycled into new clothes?',
        'placeholder': 'Please write your answer here',
    },
    [ScreensEnum.RateUs]: {
        'text1': 'Thank you for your ideas {name}! We will take them onboard. Congrats, you have now completed every step of this online experience! Before you go, we would kindly ask you, {name}, to rate this short journey. Thank you!',
        'button': 'Go to final screen',
    },
    [ScreensEnum.FinalScreen]: {
        'text1': "We appreciate your valuable time and participation! We hope you've enjoyed your experience learning about the fashion industry. Would you like to stay informed and engaged? Follow the SCIRT project on social media to be part of our awareness campaign or <a href='https://app.lgi-consulting.org/gdpr/index.php?v=36' target='_blank'>sign up</a> for the SCIRT newsletter! Stay connected and be a part of driving positive change in the fashion world!",
        'text2': "Together, let's make a difference in the fashion industry and work towards a more sustainable future."

    }
};
