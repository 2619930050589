
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenCompareChart} from "@/Screens.types";
import 'chart.js/auto';
import {Bar} from "vue-chartjs";
import ActionButton from "@/components/elements/ActionButton.vue";
import axios from "axios";

const state = useState();
export default defineComponent({
  components: {
    ActionButton,
    Bar,
    ScreenWrapper,
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenCompareChart,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false,
      showStats: false,
      chartReady: false,
      chartData: {
        labels: [],
        datasets: [{data: [], backgroundColor: '#F4E8E6'}]
      },
      answer: state.answers.value?.[this.config.answerKey],
      chartOptions: {
        indexAxis: 'y',
        responsive: true
      }
    }
  },

  mounted() {
    this.loadStats();
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext)
    }, back() {
      this.goTo(-1)
    },
    loadStats() {
      const filters = (this.config.chartSeries || [])
          .reduce((acc: {}, {filter}) => {
            if (state.answers.value?.[filter]) {
              acc[filter] = state.answers.value[filter];
            }
            return acc;
          }, {});

      axios.post(process.env.VUE_APP_SUBMIT_API + '/pitool/summary', {
        'question': this.config.answerKey,
        'filters': filters,
        source: window.location.hostname
      })
          .then((response) => {
            const answers = response.data.map((item) => item.answer).sort()
            const labels = answers.map((item) => this.$t(`${this.config.chartLabelsPrefix}.${item}`) || item);
            const values = answers.map((label) => response.data.find((item) => item.answer === label).count);

            this.chartData.labels = labels;
            this.chartData.datasets[0].data = values;


            const transValues = {};
            console.log(this.config.chartSeries);
            const that = this;
            Array.from(this.config.chartSeries).forEach(function (serie) {
              console.log(serie);
              const {filter, prefix} = serie as any;
              transValues[filter] = that.$t(`${prefix}.${state.answers.value[filter]}`);
            });

            console.log(this.chartData, transValues);

            this.chartData.datasets[0].label = this.$t(this.config.chartLabel, transValues);

            this.chartReady = true;
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }
});
