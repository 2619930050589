
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenTextsConfig} from "@/Screens.types";
import ActionButton from "@/components/elements/ActionButton.vue";

const state = useState();
export default defineComponent({
  components: {
    ActionButton,
    ScreenWrapper,
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenTextsConfig,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
    }
  },

  mounted() {
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext)
    },
  }
});
