
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenWithSlides} from "@/Screens.types";

const state = useState();
export default defineComponent({
  components: {
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenWithSlides,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      slide: '0',
      showReadMoreModal: false,
    }
  },

  mounted() {
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext);
    },
    moveNext(moveTo) {
      if (moveTo === -1) {
        return this.goTo(-1);
      }

      if (this.slide === 2) {
        this.next();
      } else {
        this.slide++;
      }
    },
  }
});
