import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8c10c8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "flex flex-row justify-between slider-ticks" }
const _hoisted_3 = ["data-index", "onClick"]
const _hoisted_4 = ["data-index", "innerHTML", "onClick"]
const _hoisted_5 = ["min", "max", "step", "list"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["value", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (option, index) => {
        return (_openBlock(), _createElementBlock("span", {
          class: "bg-white tick",
          "data-index": index,
          key: option.id,
          onClick: ($event: any) => (_ctx.change(index))
        }, null, 8, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-row justify-between options-list", {'alternate': _ctx.alternate}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (option, index) => {
        return (_openBlock(), _createElementBlock("span", {
          class: _normalizeClass(["text-xxs sm:text-xs text-left whitespace-normal option flex-1", {'whitespace-nowrap': this.unit, 'font-bold': _ctx.selectedValueIndex == index}]),
          key: option.id,
          "data-index": index,
          innerHTML: option.name,
          onClick: ($event: any) => (_ctx.change(index))
        }, null, 10, _hoisted_4))
      }), 128))
    ], 2),
    _withDirectives(_createElementVNode("input", {
      type: "range",
      class: "bg-project h-2 rounded-full mb-4 mt-8",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sliderValue) = $event)),
      min: _ctx.min || 1,
      max: _ctx.max || _ctx.list.length,
      step: _ctx.step || 1,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change(((_ctx.sliderValue - (_ctx.min || 1)) / (_ctx.step || 1))))),
      list: `tickmarks-${_ctx.listId}`
    }, null, 40, _hoisted_5), [
      [_vModelText, _ctx.sliderValue]
    ]),
    _createElementVNode("datalist", {
      id: `tickmarks-${_ctx.listId}`
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          value: option.id,
          key: option.id,
          innerHTML: option.name
        }, null, 8, _hoisted_7))
      }), 128))
    ], 8, _hoisted_6)
  ]))
}