
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenDragDropOptions} from "@/Screens.types";
import ActionButton from "@/components/elements/ActionButton.vue";

const state = useState();

function findAncestor(el, sel) {
  while (el && !((el.matches || el.matchesSelector).call(el, sel)) && (el = el.parentElement)) ;
  return el;
}

export default defineComponent({
  components: {
    ActionButton,
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenDragDropOptions,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false,
      showQuestion: false,
      done: false,
      otherModalShow: false,
      otherReason: '',
    }
  },

  mounted() {

  },

  beforeUnmount() {
    // Remove the drag images
    const dragImages = document.querySelectorAll('.hideDragImage-hide');
    dragImages.forEach((el) => {
      el.remove();
    })
  },

  methods: {
    startDrag(event: DragEvent, id) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('id', id)

      var hideDragImage = (event.target as HTMLImageElement).cloneNode(true) as HTMLImageElement;
      hideDragImage.classList.add("hideDragImage-hide")

      var dragImage = (event.target as HTMLImageElement).cloneNode(true) as HTMLImageElement;
      dragImage.classList.add("hideDragImage-hide")
      dragImage.style.position = "absolute";

      hideDragImage.style.opacity = "0";
      document.body.appendChild(hideDragImage);
      document.body.appendChild(dragImage);
      event.dataTransfer.setDragImage(hideDragImage, 0, 0);

      console.log('start drag')
    },

    dragEnter(event: DragEvent) {
      console.log(event, 'dragEnter');

      const el = findAncestor(event.target, '.drop');
      (el as Element).classList.add('drag-over')
      event.preventDefault();
    },
    dragLeave(event: DragEvent) {
      console.log(event, 'dragLeave');
      const el = findAncestor(event.target, '.drop');
      (el as Element).classList.remove('drag-over')
      event.preventDefault();
    },
    handleDrop(event: DragEvent, answer: string, text: string) {
      event.preventDefault();

      const el = findAncestor(event.target, '.drop');
      (el as Element).classList.remove('drag-over')

      if (answer === 'other') {
        this.otherModalShow = true;
        return;
      } else {
        this.answer(answer, {[answer]: text});
      }

      this.done = true;
    },


    next() {
      this.goTo(this.config.screenNext)
    }, back() {
      this.goTo(-1)
    },
    start() {
      // Open modal for nicname
      this.showNicknameModal = true;
    },
    answer(answer: string | number, texts: {}) {
      state.setAnswer(this.config.answerKey, answer, {
        ...texts,
        [this.config.answerKey]: this.$t(this.config.question)
      });
      this.otherModalShow = false;
      // this.next();
    }
  }
});
