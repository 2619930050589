import {ScreensEnum} from "@/Screens.enum";

export default {
    "screen": {
        "welcome": "¡Bienvenido!",
        "lets-start": "Empecemos...",
        "devices": "Intenta adivinar",
        "video": "¿Qué son SE?",
        "map": "¿A quién pertenecen SE?",
        "alternative-supply": "Nuestras alternativas",
        "get-magnets": "El laboratorio de imanes",
        "magnets-structure": "Fabricación de aleaciones",
        "magnets-process": "Último paso",
        "close-loop": "¡Cerrar el círculo!",
        "challenges": "Tres desafíos",
        "feedback": "¡Tu opinión!",
        "action-time": "Tiempo de acción",
        "guess": "¿Puedes adivinar cuáles de estos productos contienen aleaciones o imanes de tierras raras?"
    },
    "eu-footer": 'Este proyecto ha recibido financiación del Programa Horizonte 2020 en virtud del acuerdo de subvención n°101003906. El contenido de este sitio web refleja únicamente la opinión del autor. La Comisión Europea no se hace responsable del uso que pueda hacerse de la información que contiene.',
    "buttons": {
        "submit": "Enviar",
        "yes": "SÍ",
        "no": "NO",
        "next": "Siguiente",
        "back": "Atrás",
        "start": "¡Empecemos! {name}!",
        "go": "¡Empecemos! {name}!",
        "see": "¡Veamos!",
        "letsfindout": "¡Descubrámoslo!"
    },
    'texts': {
        'or': 'o',
        'and': 'y',
        "readless": "Leer menos",
        "readmore": "Leer más",
    },

    locales: {
        en: 'Inglés',
        es: 'Español',
        de: 'Alemán',
    },

    "defaultNickname": "Invitado",

    "intro": {
        "nickname": "Nombre",
        "par1": "Bienvenido a la experiencia interactiva SCIRT. Esta herramienta está diseñada para fomentar el compromiso y arrojar luz sobre el impacto de la industria de la moda en el medio ambiente y la sociedad. Tus valiosas aportaciones nos ayudarán a definir ideas, concienciar e impulsar cambios en la industria textil y en el comportamiento de los consumidores.",
        "par3": "¿Listo para empezar? Introduce tu nombre y haz clic en 'Empezar'.",
        "beforeButton": "Sus datos son anónimos y se utilizan únicamente con fines de investigación. <br>ÚNICAMENTE el consorcio SCIRT y los investigadores tendrán acceso."
    },

    [ScreensEnum.BringBackScreen]: {
        "question": "¿Qué te animaría a devolver tu ropa a la tienda o marca donde la compraste para que la reciclen y la conviertan en ropa nueva?",
        "placeholder": "Escribe aquí tu respuesta",
        "button": "Enviar y siguiente"
    },
    [ScreensEnum.VideoScreen]: {
        "text1": "¡Gracias por tus ideas! Las tendremos en cuenta. ¡Enhorabuena, has completado todos los pasos de esta experiencia en línea! Antes de irte, te rogamos que evalúes este breve viaje. ¡Gracias!",
        "text2": "¿Te gustaría mantenerte informado/a y comprometido/a? Sigue el proyecto SCIRT en las redes sociales para participar en nuestra campaña de sensibilización.",
        "text3": "También te invitamos a suscribirte al boletín SCIRT.",
        "button": "¡Terminar!"
    },
    [ScreensEnum.FinalScreen]: {
        "text": "¡Gracias por tus ideas! Las tendremos en cuenta. Si aún no estás listo/a para despedirte, te invitamos a quedarte unos minutos más y ayudarnos a promover un enfoque más sostenible en el sistema de la moda completando una breve encuesta sobre tus hábitos de compra de moda.",
        "survey": "Realizar la encuesta",
        "surveyLink": "https://prospex.typeform.com/to/YJAYJLwi"
    },
    [ScreensEnum.LastShopping]: {
        "text1": "Bien, {name}, empecemos con el tema de la compra de ropa.",
        "text2": "Tómate unos instantes para recordar tu última experiencia de compra de ropa. Visualiza los artículos que compraste. ¿Lo recuerdas? Excelente. Sigamos.",
        "question": "{name}, comparte con nosotros por qué compraste la ropa por favor.",
        "answer1": "Porque la necesitabas.",
        "answer2": "Porque te gustó el diseño (pero en realidad no la necesitabas)"
    },
    [ScreensEnum.BuyingCriteria]: {
        "text1": "Te agradecemos tu respuesta. Ahora pasemos a la siguiente pregunta:",
        "question": "¿Qué criterios consideras más importantes a la hora de comprar ropa? Por favor, ordena las prioridades moviendo las casillas de la lista de la más a la menos importante.",
        "hint": "Arrastrar para reordenar",
        "price": "Precio",
        "quality": "Calidad (si es duradero)",
        "esthetics": "Estética",
        "ecofriendly": "Respeto al medio ambiente",
        "material": "Material (natural antes que sintético)",
        "other": "Otros / Especifica aquí",
        "fairAndHumane": "Producción justa y humana"
    },
    [ScreensEnum.HowMuchSpendOnJeans]: {
        "text1": "¡Gracias, {name}! Concretemos un poco más y hablemos de precios:",
        "question": "¿Cuánto te gastas en unos vaqueros?"
    },
    [ScreensEnum.WhatToPayForPants]: {
        "question": "¿Cuánto estarías dispuesto/a a pagar por unos pantalones para la oficina?"
    },
    [ScreensEnum.HowMuchSwimmwear]: {
        "question": "¿Cuánto estarías dispuesto/a a gastar de media en ropa de baño?"
    },
    [ScreensEnum.InitalQuestionsThankYou]: {
        "text1": "¡Vaya, muchas gracias {name} por tu gran aportación!",
        "text11": "Tus respuestas nos ayudan a comprender las diferencias entre los países de la UE y a explorar posibles vínculos entre la demografía y el comportamiento de los consumidores.",
        "text2": "Ten por seguro que todas tus respuestas serán tratadas con la máxima confidencialidad y anonimato.",
        "text3": "<b>Ahora vamos a conocerte mejor.</b> Dedica un momento a responder a las siguientes preguntas demográficas."
    },
    [ScreensEnum.AgeGroup]: {
        "question": "1. ¿Qué edad tienes?",
        "below-18": "Menos de 18 años",
        "18-30": "18 - 30",
        "30-40": "30 - 40",
        "40-50": "40 - 50",
        "50-60": "50 - 60",
        "60-70": "60 - 70",
        "above-70": "Más de 70"
    },
    [ScreensEnum.Country]: {
        "question": "2. ¿Dónde vives?"
    },
    [ScreensEnum.Gender]: {
        "question": "3. ¿Cuál es tu género?",
        "female": "Mujer",
        "male": "Hombre",
        "non-binary": "No binario",
        "not-say": "Prefiero no contestar"
    },
    'WaterNeededForShirt': {
        "text1": "¡Gracias por tu aportación tan valiosa! Tus respuestas ya han contribuido en gran medida a nuestra investigación.",
        "text2": "Ahora vamos a sumergirnos en algunos <b>datos interesantes sobre la industria de la moda</b>. ¿Estás preparado/a, {name}?",
        "question": "¿Sabes cuánta agua se necesita para producir una camiseta?",
        "ans_ok": "¡Veamos, {name}!",
        "ans_nok": "¡Uy, lo has subestimado!",
        "ans_text": "Esta es la cantidad de agua necesaria para una sola camiseta de algodón.",
        "ans_nok_text": "Esta es la cantidad de agua necesaria para una sola camiseta de algodón.",
        "fact": "Para ponerlo en perspectiva, ¡esta cantidad de agua sería suficiente para que una persona bebiera durante 2 años y medio!"
    },
    [ScreensEnum.Emissions]: {
        "question": "¿Tienes curiosidad por saber cuál es el impacto de la industria de la moda en las emisiones mundiales de CO2? ¿De qué porcentaje de las emisiones mundiales de CO2 es responsable la industria de la moda? Averigüémoslo.",
        "ans_ok": "Toda la razón. ¡Lo has clavado!",
        "ans_ok_text": "La industria de la moda representa aproximadamente entre el 2% y el 5% de las emisiones mundiales de carbono, ¡superando las emisiones combinadas de las industrias de la aviación y el transporte marítimo! ¡Impresionantes conocimientos!",
        "ans_nok": "De hecho, es menos...",
        "ans_nok_text": "La industria de la moda genera entre el 2% y el 5% de las emisiones mundiales de carbono, es decir, más que la aviación y el transporte marítimo juntos.",
        "source": "Fuente: https://www.wri.org/research/roadmap-net-zero-delivering-science-based-targets-apparel-sector"
    },
    [ScreensEnum.HowManyPeopleEmployed]: {
        "question": "¿A cuántas personas emplea la industria de la moda en todo el mundo?",
        "100mil": "100 millones",
        "200mil": "200 millones de personas",
        "300mil": "300 millones de personas",
        "400mil": "400 millones de personas",
        "ans_ok_title": "¡Justo!",
        "ans_nok_title": "No del todo.",
        "ans_ok_text": "La industria mundial de la moda emplea a unos 300 millones de personas, con una amplia gama de niveles salariales en función de la experiencia y la ubicación.",
        "ans_nok_text": "La industria de la moda emplea a unos 300 millones de personas, con una amplia gama de salarios.",
        "source": "MacArthur, E. (2017). Foundation A New Textiles Economy: Redesigning Fashion’s Future. London, UK."
    },
    [ScreensEnum.WhereDoYouBuyClothes]: {
        "text1": "Ahora que hemos explorado algunos aspectos de la industria de la moda, retomemos tus propias preferencias.",
        "question": "¿Dónde compras normalmente tu ropa?",
        "physical": "Solo offline <br> (tiendas físicas)",
        "online": "Solo online"
    },
    [ScreensEnum.ShoppingHabits]: {
        "intro": "Utilizar esta escala Likert estructurada nos permitirá conocer mejor tus preferencias y hábitos a la hora de vestir. No dudes en darnos tus respuestas más sinceras.",
        "question1": "¿Con qué frecuencia compras ropa de marcas conocidas como Zara, H&M, GAP, Primark, Shein, Uniqlo, etc.?",
        "question2": "¿Con qué frecuencia compras ropa de marcas más sostenibles como HNST, Organic Basics, Reformation, etc.?",
        "question3": "¿Con qué frecuencia compras ropa de marcas pequeñas o de diseñadores locales?",
        "question4": "¿Con qué frecuencia compras ropa de segunda mano?",
        "question5": "¿Te haces tu propia ropa?",
        "never": "Nunca",
        "rarely": "Raramente",
        "sometimes": "A veces",
        "often": "A menudo",
        "always": "Siempre",
        "additionalOptionsText": "No dudes en aportar más información aquí"
    },
    [ScreensEnum.HowManyProduced]: {
        "intro": "<b>Volviendo a los datos de interés...</b>",
        "question": "¿Adivinas cuántas prendas de moda produce cada año la industria de la moda en todo el mundo?",
        "500-600mil": "500 - 600 millones",
        "1-15bil": "De 1.000 a 1.500 millones",
        "50-60bil": "50.000 - 60.000 millones",
        "100-150bil": "100.000 - 150.000 millones",
        "ans_ok_title": "¡Correcto {name}!",
        "ans_nok_title": "Es mucho más.",
        "ans_ok_text": "La cifra estimada es de 100.000 a 150.000 millones de artículos al año.",
        "ans_nok_text": "La cifra estimada es de 100.000 a 150.000 millones de artículos al año.",
        "source": "Rudenko, O. (2018). The 2018 apparel industry overproduction report and infographic. Share Cloth"
    },
    [ScreensEnum.HowOftenConsiderSustainability]: {
        "question": "Ahora nos pica la curiosidad por otra cosa. En general, ¿con qué frecuencia tienes en cuenta las prácticas sostenibles en tu vida diaria?",
        "idont": "No las tengo en cuenta en absoluto",
        "rarely": "Rara vez las tengo en cuenta",
        "somewhat": "Me lo planteo un poco",
        "often": "Me lo planteo a menudo",
        "always": "Hago todo lo que puedo para ser sostenible"
    },
    [ScreensEnum.HowYouCompare]: {
        "text1": "¡Gracias, {name}! Tus opiniones son muy útiles para comprender mejor las prácticas sostenibles.",
        "text2": "Veamos ahora cómo te comparas con tus compañeros de la misma edad que han participado en este cuestionario.",
        "chart-label": "Grupo de edad: {AgeGroup}, género: {Gender}"
    },
    [ScreensEnum.DragDropShirt]: {
        "question": "Dediquemos un momento a reflexionar sobre una camiseta u otra prenda de ropa que tengamos pero que ya no usemos. ¿Qué sueles hacer con estas prendas?",
        "dragShirtText": "Arrastra y suelta la camiseta en la casilla correspondiente.",
        "secondhand": "Vender de segunda mano",
        "garbage": "Tirar la basura",
        "gift": "Regalar a un amigo / familiar",
        "reuse": "Reutilizar (hacer otra cosa con ello)",
        "keep": "Guardar en el armario durante mucho tiempo",
        "container": "Contenedor textil",
        "charity": "Donación benéfica",
        "bringback": "Devolver a la tienda donde la compraste",
        "other": "Otra solución",
        "other-text": "Escribe aquí tus ideas"
    },
    [ScreensEnum.NotSoldGarments]: {
        "intro": "La producción de entre 100.000 y 150.000 millones de prendas al año genera un volumen importante de residuos.",
        "question": "Veamos qué ocurre con estas prendas una vez fabricadas. ¿Adivinas qué parte de la ropa producida nunca llega a venderse?",
        "10-percent": "10 %",
        "20-percent": "20 %",
        "30-percent": "30 %",
        "40-percent": "40 %",
        "ans_ok_title": "Tienes razón, {name}.",
        "ans_nok_title": "No del todo.",
        "ans_ok_text": "Se calcula que alrededor del 30% de toda la ropa que se produce nunca se vende. Esto significa que existe una importante sobreproducción en la industria de la moda.",
        "ans_nok_text": "Se calcula que alrededor del 30% de toda la ropa que se produce nunca se vende. Esto significa que existe una importante sobreproducción en la industria de la moda.",
        "source": "Australian Circular Textile Association (ACTA), (2019)."
    },
    [ScreensEnum.HowMuchClothesBoughtKg]: {
        "question": "Sin embargo, la mayor parte de la ropa sigue acabando en los armarios de los consumidores. ¿Adivinas cuánta ropa, calzado y textiles para el hogar compra cada año el consumidor europeo medio? ¡Comparte tu estimación con nosotros!",
        "ans_ok_title": "¡En efecto!",
        "ans_nok_title": "Casi, pero no del todo.",
        "ans_ok_text": "El europeo medio consume 26 kg de productos textiles al año.",
        "ans_nok_text": "Es más de lo que crees. Un europeo medio consume 26 kg de productos textiles al año.",
        "5kg": "aprox. 5 kg",
        "9kg": "aprox. 9 kg",
        "15kg": "aprox. 15 kg",
        "26kg": "aprox. 26 kg",
        "source": "Manshoven, S., Christis, M., Vercalsteren, A., Arnold, M., Nicolau, M., Lafond, E., ... & Coscieme, L. (2019). Textiles and the environment in a circular economy. Eur Top Cent Waste Mater a Green Econ, 1-60."
    },
    [ScreensEnum.HowManyTShirts]: {
        "question": "Los consumidores de la UE no sólo compran mucha ropa, sino que también desechan una cantidad importante: cada persona desecha una media de 11 kg de productos textiles al año. ¿Adivinas a cuántas camisetas equivale esto?",
        "20-shirts": "20 camisetas",
        "40-shirts": "40 camisetas",
        "60-shirts": "60 camisetas",
        "75-shirts": "75 camisetas",
        "ans_ok_title": "¡Así es!",
        "ans_nok_title": "Piensa más.",
        "ans_ok_text": "Esto se traduce en unas 75 camisetas. Es lo mismo que tirar una camiseta cada cinco días.",
        "ans_nok_text": "Esto se traduce en unas 75 camisetas. Es lo mismo que tirar una camiseta cada cinco días.",
        "source": "Beasley, J., Georgeson, R., Arditi, S., & Barczak, P. (2014). Advancing Resource Efficiency in Europe: Indicators and waste policy scenarios to deliver a resource efficient and sustainable Europe. Brussels: European Environmental Bureau (EEB)."
    },
    [ScreensEnum.WasteSlides]: {
        "text1": "¡Gracias por tus respuestas, apreciamos mucho tu aportación!",
        "text2": "Ahora exploremos el destino típico de los residuos textiles en Europa. De los 5.8 millones de toneladas de residuos textiles que se producen anualmente en Europa, la distribución estimada es la siguiente:",
        "source": "Manshoven, S., Christis, M., Vercalsteren, A., Arnold, M., Nicolau, M., Lafond, E., ... & Coscieme, L. (2019). Textiles and the environment in a circular economy. Eur Top Cent Waste Mater a Green Econ, 1-60",

        "readMoreModal_title": "Perspectiva <br> global",
        "readMoreModal_slide0_text1": "Cada año se producen en el mundo 92 millones de toneladas de residuos textiles.",
        "readMoreModal_slide0_text2": "La ropa y los textiles representan actualmente al menos el 7% de la cantidad total de residuos en los vertederos mundiales.",
        "readMoreModal_slide0_text3": "La producción textil genera 42 millones de toneladas de residuos plásticos al año, lo que convierte a la industria textil en el segundo sector industrial, después de los envases.",
        "readMoreModal_slide0_text4": "Cada vez que se lava una prenda sintética, se liberan diminutas microfibras de plástico en el agua. Hasta 500,000 toneladas de microfibras acaban en el océano cada año.",
        "readMoreModal_slide0_text5": "El 87% de los materiales y fibras utilizados para confeccionar prendas de vestir acaban en incineradores o vertederos.",
        "readMoreModal_slide1_text1": "La ropa se fabrica y se tira a un ritmo alarmante. Debido a ello, un montón de ropa es ahora visible desde el Espacio. La enorme pila de ropa crece unas 39,000 toneladas al año.",
        "readMoreModal_sources_slide0": "Fuente: UNEP, Global Fashion Agenda, Fundación Ellen MacArthur, Science Advances.",
        "readMoreModal_sources_slide1": 'Fuente: ¡Alta moda! Una montaña de ropa desechada en el desierto chileno es visible desde el espacio (foto satélite), por Robert Lea, publicado el 22 de junio de 2023'
    },

    [ScreensEnum.Statistics]: {
        "text1": "Estas estadísticas arrojan luz sobre la mejora de las prácticas de reciclaje y sostenibilidad en la industria de la moda.",
        "text2": "El hecho de que menos del 1% de los residuos textiles se reciclen en nuevas prendas pone de manifiesto la necesidad acuciante de seguir mejorando en este ámbito.",
        "text3": "No cabe duda de que existe una gran necesidad de abordar las prácticas en materia de residuos textiles.",
        "text4": "¿Qué se puede hacer para mejorar la situación?",
        "button": "¡Averigüémoslo!"
    },
    [ScreensEnum.ShareIdeasTexts]: {
        "text1": "Para avanzar y mejorar la situación, tenemos que dar varios pasos vitales. Por suerte, ya hay muchos agentes trabajando por un cambio positivo.",
        "text2": "Por ejemplo, el proyecto SCIRT, actualmente en marcha y financiado por la UE, está desarrollando activamente nuevas formas de crear y vender ropa a partir de materiales reciclados. El objetivo es cerrar el círculo y lograr un sistema de moda totalmente circular mediante la recogida y utilización de prendas viejas para producir otras nuevas.",
        "text3": "Al participar en esta experiencia en línea, ya estás contribuyendo a estos avances al ser más consciente del sistema de moda actual.",
        "text4": "Antes de terminar, tenemos una petición más. Por favor, ¡comparte tus ideas con nosotros!"
    },
    [ScreensEnum.ShareIdeasQuestion]: {
        "question": "¿Qué te animaría a devolver tu ropa a la tienda o marca donde la compraste para que la reciclen y la conviertan en ropa nueva?",
        "placeholder": "Escribe aquí tu respuesta"
    },
    [ScreensEnum.RateUs]: {
        "text1": "¡Gracias por tus aportaciones, {name}! Las tendremos en cuenta. Enhorabuena, ya has completado todos los pasos de esta experiencia en línea. Antes de irte, te rogamos, {name}, que evalúes este breve viaje. ¡Gracias!",
        "button": "Ir a la pantalla final"
    },
    [ScreensEnum.FinalScreen]: {
        "text1": "Te agradecemos tu valioso tiempo y tu participación. Esperamos que hayas disfrutado de tu experiencia aprendiendo sobre la industria de la moda. ¿Te gustaría mantenerte informado/a y comprometido/a? Sigue el proyecto SCIRT en las redes sociales para participar en nuestra campaña de sensibilización o <a href='https://app.lgi-consulting.org/gdpr/index.php?v=36' target='_blank'>suscríbete al boletín SCIRT</a>. ¡Mantente conectado/a y participa en el impulso de un cambio positivo en el mundo de la moda!",
        "text2": "Juntos, marquemos la diferencia en la industria de la moda y trabajemos por un futuro más sostenible."
    }
};
