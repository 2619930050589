import {ScreensEnum} from "@/Screens.enum";

export default {
    "screen": {
        "welcome": "Willkommen!",
        "lets-start": "Lassen Sie uns beginnen...",
        "devices": "Versuchen Sie, zu raten",
        "video": "Was sind SE?",
        "map": "Wem gehören SE?",
        "alternative-supply": "Unsere Alternativen",
        "get-magnets": "Das Magnetlabor",
        "magnets-structure": "Legierungsherstellung",
        "magnets-process": "Letzter Schritt",
        "close-loop": "Den Kreis schliessen!",
        "challenges": "Drei Herausforderungen",
        "feedback": "Ihr Feedback!",
        "action-time": "Zeit für Action",
        "guess": "Können Sie erraten, welche dieser Produkte Legierungen oder Magnete aus Seltenen Erden enthalten?"
    },
    "eu-footer": 'Dieses Projekt wurde mit Mitteln des Programms Horizont 2020 unter der Finanzhilfevereinbarung Nr. 101003906 gefördert. Der Inhalt dieser Website gibt ausschließlich die Meinung des Autors wieder. Die Europäische Kommission kann nicht für die Verwendung der darin enthaltenen Informationen verantwortlich gemacht werden.',
    "buttons": {
        "submit": "Einreichen",
        "yes": "JA",
        "no": "NEIN",
        'next': 'Weiter',
        'back': 'Zurück',
        "start": "Los geht's! {name}!",
        "go": "Los geht's! {name}!",
        "see": "Los!",
        "letsfindout": "Lass es uns herausfinden!",
    },

    'texts': {
        'or': 'oder',
        'and': 'und',
        "readless": "Weniger lesen",
        "readmore": "Mehr lesen",
    },

    locales: {
        en: 'Englisch',
        de: 'Deutsch',
        es: 'Spanisch',
    },

    'defaultNickname': 'Gast',

    "intro": {
        "nickname": "Nickname",
        "par1": `Willkommen bei der interaktiven SCIRT-Erfahrung. Dieses Instrument soll das Engagement fördern und die Auswirkungen der Modeindustrie auf die Umwelt und die Gesellschaft beleuchten. Ihr wertvoller Beitrag wird uns helfen, Ideen zu entwickeln, das Bewusstsein zu schärfen und Veränderungen in der Textilindustrie und im Verbraucherverhalten voranzutreiben.`,
        "par3": 'Sind Sie bereit, mitzumachen? Geben Sie Ihren Namen ein und klicken Sie auf "Los geht\'s".',
        "beforeButton": 'Ihre Daten werden anonymisiert und nur für Forschungszwecke verwendet. <br>NUR das SCIRT-Konsortium und die Forscher haben Zugang zu Ihren Daten. ',
    },

    [ScreensEnum.BringBackScreen]: {
        'question': 'Was würde Sie dazu ermutigen, Ihre Kleidung zu dem Geschäft oder der Marke zurückzubringen, bei der Sie sie gekauft haben, damit sie zu neuer Kleidung recycelt werden kann?',
        'placeholder': 'Bitte tragen Sie Ihre Antwort hier ein',
        'button': 'Senden und weiter',
    },
    [ScreensEnum.VideoScreen]: {
        'text1': 'Vielen Dank für Ihre Ideen! Wir werden sie aufgreifen. Herzlichen Glückwunsch, Sie haben nun alle Schritte dieser Online-Erfahrung durchlaufen! Bevor Sie gehen, möchten wir Sie bitten, diese kurze Reise zu bewerten. Vielen Dank!',
        'text2': 'Möchten Sie informiert und beteiligt bleiben? Folgen Sie dem SCIRT-Projekt in den sozialen Medien, um an unserer Sensibilisierungskampagne teilzunehmen.',
        'text3': 'Wir laden Sie auch ein, sich für den SCIRT-Newsletter anzumelden.',
        'button': 'Beenden!',
    },

    [ScreensEnum.FinalScreen]: {
        'text': "Vielen Dank für Ihre Ideen! Wir werden sie aufgreifen. Wenn Sie noch nicht bereit sind, sich zu verabschieden, laden wir Sie herzlich ein, noch ein paar Minuten länger zu bleiben und uns dabei zu helfen, einen nachhaltigeren Ansatz im Modesystem zu fördern, indem Sie eine kurze Umfrage über Ihre Modeeinkaufsgewohnheiten ausfüllen.",
        'survey': 'Zur Umfrage',
        'surveyLink': 'https://prospex.typeform.com/to/YJAYJLwi',
    },
    [ScreensEnum.LastShopping]: {
        'text1': 'Okay, {name}, fangen wir mit dem Thema "Kleiderkauf" an.',
        'text2': 'Nehmen Sie sich einen Moment Zeit und denken Sie an Ihren letzten Kleiderkauf zurück. Stellen Sie sich vor, was Sie gekauft haben. Erinnern Sie sich? Sehr gut. Machen wir weiter.',
        'question': '{name}, erzählen Sie uns bitte, warum Sie die Kleidung gekauft haben?',
        'answer1': 'Weil Sie sie brauchten.',
        'answer2': 'Weil Ihnen das Design gefiel (aber Sie brauchten sie nicht wirklich)',
    },
    [ScreensEnum.BuyingCriteria]: {
        'text1': 'Ich danke Ihnen für Ihre Antwort. Lassen Sie uns nun zur nächsten Frage übergehen:',
        'question': 'Welche Kriterien sind für Sie beim Einkauf von Kleidung am wichtigsten? Bitte setzen Sie Prioritäten, indem Sie die Kästchen in der Liste von "am wichtigsten" nach "am wenigsten wichtig" verschieben.',
        'hint': 'Ziehen, um neu zu ordnen',
        'price': 'Preis',
        'quality': 'Qualität (wenn langlebig)',
        'esthetics': 'Ästhetik',
        'ecofriendly': 'Umweltfreundlichkeit',
        'material': 'Material (natürlich und nicht synthetisch)',
        'other': 'Andere / Bitte spezifizieren hier',
        'fairAndHumane': 'Faire und humane Produktion',
    },
    [ScreensEnum.HowMuchSpendOnJeans]: {
        'text1': 'Vielen Dank, {name}! Lassen Sie uns etwas konkreter werden und über die Preise sprechen:',
        'question': 'Wie viel geben Sie für Jeans aus?',
    },
    [ScreensEnum.WhatToPayForPants]: {
        'question': 'Wie viel wären Sie bereit, für eine Hose für das Büro zu bezahlen?',
    },
    [ScreensEnum.HowMuchSwimmwear]: {
        'question': 'Wie viel sind Sie bereit, im Durchschnitt für Bademode auszugeben?',
    },
    [ScreensEnum.InitalQuestionsThankYou]: {
        'text1': "Wow, vielen Dank an {name} für Ihren tollen Beitrag!",
        'text11': "Mit Ihren Antworten helfen Sie uns, die Unterschiede zwischen den EU-Ländern zu verstehen und mögliche Zusammenhänge zwischen Demografie und Verbraucherverhalten zu untersuchen.",
        'text2': 'Seien Sie sich sicher, dass alle Ihre Antworten mit höchster Vertraulichkeit und Anonymität behandelt werden.',
        'text3': '<b>Jetzt wollen wir Sie besser kennen lernen.</b> Nehmen Sie sich einen Moment Zeit, um die folgenden demografischen Fragen zu beantworten.'
    },
    [ScreensEnum.AgeGroup]: {
        'question': '1. Zu welcher Altersgruppe gehören Sie?',
        'below-18': 'Unter 18 Jahre alt',
        '18-30': '18 - 30',
        '30-40': '30 - 40',
        '40-50': '40 - 50',
        '50-60': '50 - 60',
        '60-70': '60 - 70',
        'above-70': 'Mehr als 70',
    },
    [ScreensEnum.Country]: {
        'question': '2. Wo wohnen Sie?',
    },
    [ScreensEnum.Gender]: {
        'question': '3. Welches Geschlecht haben Sie?',
        'female': 'Weiblich',
        'male': 'Männlich',
        'non-binary': 'Nicht-binär',
        'not-say': 'Ich ziehe es vor, nicht zu antworten',
    },
    'WaterNeededForShirt': {
        'text1': "Vielen Dank für Ihren wertvollen Beitrag! Ihre Antworten haben bereits viel zu unserer Forschung beigetragen.",
        'text2': 'Jetzt wollen wir uns ein paar <b>interessante Fakten über die Modeindustrie</b> ansehen. Bist du bereit, {name}?',
        'question': 'Weißt du, wie viel Wasser für die Herstellung eines T-Shirts benötigt wird?',
        'ans_ok': 'Schauen wir mal {name}!',
        'ans_nok': 'Ups, das haben Sie unterschätzt!',
        'ans_text': 'Das ist die Wassermenge, die für ein einziges Baumwoll T-Shirt benötigt wird.',
        'ans_nok_text': 'Das ist die Wassermenge, die für ein einziges Baumwoll T-Shirt benötigt wird.',
        'fact': 'Zum Vergleich: Diese Wassermenge würde ausreichen, um eine Person 2,5 Jahre lang zu versorgen!'
    },
    [ScreensEnum.Emissions]: {
        'question': 'Sind Sie neugierig, welchen Einfluss die Modeindustrie auf die weltweiten CO2-Emissionen hat? <b>Für welchen Prozentsatz der weltweiten CO2-Emissionen ist die Modeindustrie verantwortlich?</b> Finden wir es heraus.',
        'ans_ok': 'Vollkommen richtig - Sie haben es auf den Punkt gebracht!',
        'ans_ok_text': 'Die Modeindustrie ist für ca. 2-5 % der weltweiten Kohlenstoffemissionen verantwortlich und übertrifft damit die Emissionen der Luft- und Schifffahrtsindustrie zusammengenommen! Beeindruckende Erkenntnisse!',
        'ans_nok': 'In der Tat ist es weniger...',
        'ans_nok_text': "Die Modeindustrie verursacht zwischen 2 und 5 % der weltweiten Kohlenstoffemissionen - das ist mehr als der Luft- und Schiffsverkehr zusammen.",
        'source': 'Source: https://www.wri.org/research/roadmap-net-zero-delivering-science-based-targets-apparel-sector',
    },
    [ScreensEnum.HowManyPeopleEmployed]: {
        'question': 'Wie viele Menschen sind weltweit in der Modebranche beschäftigt?',
        '100mil': '100 Millionen Menschen',
        '200mil': '200 Millionen Menschen',
        '300mil': '300 Millionen Menschen',
        '400mil': '400 Millionen Menschen',
        'ans_ok_title': 'Das ist richtig!',
        'ans_nok_title': 'Nicht ganz.',
        'ans_ok_text': 'In der weltweiten Modeindustrie sind rund 300 Millionen Menschen beschäftigt, und je nach Erfahrung und Standort gibt es ein breites Spektrum an Gehaltsstufen.',
        'ans_nok_text': 'In der Modeindustrie sind rund 300 Millionen Menschen beschäftigt, mit einer großen Bandbreite an Gehältern.',
        'source': "MacArthur, E. (2017). Foundation A New Textiles Economy: Redesigning Fashion’s Future. London, UK.",
    },
    [ScreensEnum.WhereDoYouBuyClothes]: {
        'text1': 'Nachdem wir nun einige Aspekte der Modeindustrie erforscht haben, wollen wir uns nun Ihre eigenen Vorlieben ansehen.',
        'question': 'Wo kaufen Sie normalerweise Ihre Kleidung?',
        'physical': "Nur offline <br> (physische Geschäfte)",
        'online': 'Nur online',
    },
    [ScreensEnum.ShoppingHabits]: {
        'intro': 'Mithilfe dieser strukturierten Likert-Skala können wir mehr über Ihre Kleidungsvorlieben und -gewohnheiten erfahren. Fühlen Sie sich frei, uns Ihre ehrlichen Antworten zu geben.',
        'question1': "Wie oft kaufen Sie Kleidung von bekannten Marken wie Zara, H&M, GAP, Primark, Shein, Uniqlo, usw.?",
        'question2': "Wie oft kaufen Sie Kleidung von nachhaltigeren Marken wie HNST, Organic Basics, Reformation, usw.?",
        'question3': "Wie oft kaufen Sie Kleidung von kleineren Marken oder lokalen Designern?",
        'question4': "Wie oft kaufen Sie Kleidung aus zweiter Hand?",
        'question5': "Stellen Sie Ihre Kleidung selbst her?",
        'never': 'Nie',
        'rarely': 'Selten',
        'sometimes': 'Manchmal',
        'often': 'Oft',
        'always': 'Immer',
        'additionalOptionsText': 'Hier können Sie weitere Informationen angeben',
    },
    [ScreensEnum.HowManyProduced]: {
        'intro': '<b>Zurück zu den faszinierenden Fakten...</b>',
        'question': 'Können Sie schätzen, wie viele Kleidungsstücke die Modeindustrie jedes Jahr weltweit herstellt?',
        '500-600mil': '500 - 600 Millionen',
        '1-15bil': '1 - 1.5 Milliarden',
        '50-60bil': '50 - 60 Milliarden',
        '100-150bil': '100 - 150 Milliarden',
        'ans_ok_title': 'Korrekt {name}!',
        'ans_nok_title': 'Es sind viel mehr.',
        'ans_ok_text': 'Die geschätzte Zahl liegt bei 100 - 150 Milliarden Tonnen pro Jahr!',
        'ans_nok_text': 'Die geschätzte Zahl liegt bei 100 - 150 Milliarden Tonnen pro Jahr!',
        'source': "Rudenko, O. (2018). The 2018 apparel industry overproduction report and infographic. Share Cloth",
    },
    [ScreensEnum.HowOftenConsiderSustainability]: {
        'question': 'Jetzt sind wir neugierig auf etwas anderes: Wie oft denken Sie in Ihrem Alltag über nachhaltige Praktiken nach?',
        'idont': 'Ich denke überhaupt nicht darüber nach',
        'rarely': 'Ich berücksichtige sie selten',
        'somewhat': 'Ich berücksichtige sie etwas',
        'often': 'Ich berücksichtige sie oft',
        'always': 'Ich tue alles, was ich kann, um nachhaltig zu sein',
    },
    [ScreensEnum.HowYouCompare]: {
        'text1': 'Vielen Dank, {name}! Ihre Erkenntnisse sind wertvoll, um ein breiteres Verständnis für nachhaltige Praktiken zu gewinnen.',
        'text2': 'Nun wollen wir sehen, wie Sie im Vergleich zu Gleichaltrigen dastehen, die an diesem Fragebogen teilgenommen haben.',
        'chart-label': 'Altersgruppe: {AgeGroup}, Geschlecht: {Gender}',
    },
    [ScreensEnum.DragDropShirt]: {
        'question': 'Nehmen wir uns einen Moment Zeit, um über ein T-Shirt oder ein anderes Kleidungsstück nachzudenken, das Sie besitzen, aber nicht mehr tragen. Was machen Sie normalerweise mit diesen Gegenständen?',
        'dragShirtText': "Ziehen Sie das T-Shirt in das entsprechende Feld und legen Sie es dort ab.",
        'secondhand': 'Verkaufen aus zweiter Hand',
        'garbage': 'Mülltonne',
        'gift': 'Geschenk für einen Freund / Familienmitglied',
        'reuse': "Wiederverwenden (etwas anderes daraus machen)",
        'keep': 'Lange im Kleiderschrank aufbewahren',
        'container': 'Textilcontainer',
        'charity': 'Spende für wohltätige Zwecke',
        'bringback': "Rückgabe an das Geschäft, in dem du es gekauft hast",
        'other': 'Andere Lösung',
        'other-text': 'Schreibe deine Ideen hier',
    },
    [ScreensEnum.NotSoldGarments]: {
        'intro': 'Die 100-150 Milliarden Kleidungsstücke, die jedes Jahr produziert werden, führen zu einer erheblichen Gesamtabfallmenge.',
        'question': 'Wir wollen untersuchen, was mit diesen Kleidungsstücken nach ihrer Herstellung geschieht. Können Sie erraten, wie viel der produzierten Kleidungsstücke gar nicht erst verkauft wird?',
        '10-percent': '10 %',
        '20-percent': '20 %',
        '30-percent': '30 %',
        '40-percent': '40 %',
        'ans_ok_title': 'Du hast Recht, {name}!',
        'ans_nok_title': 'Nicht ganz.',
        'ans_ok_text': 'Man schätzt, dass etwa 30 % aller produzierten Kleidungsstücke nie verkauft werden. Das bedeutet, dass es in der Modeindustrie eine erhebliche Überproduktion gibt.',
        'ans_nok_text': 'Es wird geschätzt, dass etwa 30 % aller produzierten Kleidungsstücke nie verkauft werden. Das bedeutet, dass es in der Modeindustrie eine erhebliche Überproduktion gibt.',
        'source': 'Australian Circular Textile Association (ACTA), (2019).',
    },
    [ScreensEnum.HowMuchClothesBoughtKg]: {
        'question': 'Die meisten Kleidungsstücke landen jedoch immer noch in den Kleiderschränken der Verbraucher. Können Sie erraten, wie viele Kleidungsstücke, Schuhe und Heimtextilien der europäische Durchschnittsverbraucher jedes Jahr kauft? Teilen Sie uns Ihre Schätzung mit!',
        'ans_ok_title': 'Genau richtig!',
        'ans_nok_title': 'Nah dran, aber nicht ganz.',
        'ans_ok_text': 'Der durchschnittliche Europäer verbraucht 26 kg Textilien pro Jahr!',
        'ans_nok_text': 'Es ist mehr als Sie denken. Der durchschnittliche Europäer verbraucht 26 kg Textilien pro Jahr!',
        '5kg': 'ca 5 kg',
        '9kg': 'ca 9 kg',
        '15kg': 'ca 15 kg',
        '26kg': 'ca 26 kg',
        'source': "Manshoven, S., Christis, M., Vercalsteren, A., Arnold, M., Nicolau, M., Lafond, E., ... & Coscieme, L. (2019). Textiles and the environment in a circular economy. Eur Top Cent Waste Mater a Green Econ, 1-60.",
    },
    [ScreensEnum.HowManyTShirts]: {
        'question': "Die Verbraucher in der EU kaufen nicht nur ausgiebig Kleidung, sondern werfen auch eine beträchtliche Menge weg: Im Durchschnitt werden 11 kg Textilien pro Person und Jahr weggeworfen. Können Sie sich vorstellen, wie viele T-Shirts das sind?",
        '20-shirts': '20 T-Shirts',
        '40-shirts': '40 T-Shirts',
        '60-shirts': '60 T-Shirts',
        '75-shirts': '75 T-Shirts',
        'ans_ok_title': 'Volle Punktzahl!',
        'ans_nok_title': 'Denken Sie mehr.',
        'ans_ok_text': 'Das entspricht etwa 75 T-Shirts. Das ist dasselbe, als wenn Sie alle fünf Tage ein T-Shirt wegwerfen würden.',
        'ans_nok_text': 'Das entspricht etwa 75 T-Shirts. Das ist dasselbe, als wenn Sie alle fünf Tage ein T-Shirt wegwerfen würden.',
        'source': "Beasley, J., Georgeson, R., Arditi, S., & Barczak, P. (2014). Advancing Resource Efficiency in Europe: Indicators and waste policy scenarios to deliver a resource efficient and sustainable Europe. Brussels: European Environmental Bureau (EEB).",
    },
    [ScreensEnum.WasteSlides]: {
        "text1": "Vielen Dank für Ihre Antworten, wir schätzen Ihren Beitrag sehr!",
        "text2": "Lassen Sie uns nun das typische Schicksal von Textilabfällen in Europa untersuchen. Von den jährlich in Europa produzierten 5,8 Millionen Tonnen Textilabfall ist die geschätzte Verteilung wie folgt:",
        "source": "Manshoven, S., Christis, M., Vercalsteren, A., Arnold, M., Nicolau, M., Lafond, E., ... & Coscieme, L. (2019). Textiles and the environment in a circular economy. Eur Top Cent Waste Mater a Green Econ, 1-60",

        "readMoreModal_title": "Globale <br> Perspektive",
        "readMoreModal_slide0_text1": "Jedes Jahr werden weltweit 92 Millionen Tonnen Textilabfälle produziert.",
        "readMoreModal_slide0_text2": "Kleidung und Textilien machen derzeit mindestens 7% der gesamten Abfallmenge auf Deponien weltweit aus.",
        "readMoreModal_slide0_text3": "Die Textilproduktion erzeugt jährlich 42 Millionen Tonnen Plastikabfälle und macht die Textilindustrie damit zum zweithöchsten Industriesektor nach der Verpackungsindustrie.",
        "readMoreModal_slide0_text4": "Jedes Mal, wenn ein synthetisches Kleidungsstück gewaschen wird, werden winzige Plastikmikrofasern ins Wasser freigesetzt. Bis zu 500.000 Tonnen Mikrofasern gelangen jedes Jahr in die Ozeane.",
        "readMoreModal_slide0_text5": "87% der Materialien und Fasern, die zur Herstellung von Kleidung verwendet werden, landen entweder in Verbrennungsanlagen oder auf Deponien.",
        "readMoreModal_slide1_text1": "Kleidung wird in alarmierendem Tempo hergestellt und weggeworfen. Aus diesem Grund ist jetzt ein Kleiderhaufen aus dem Weltraum sichtbar. Der riesige Kleiderberg wächst jedes Jahr um etwa 39.000 Tonnen.",
        "readMoreModal_sources_slide0": "Quelle: UNEP, Global Fashion Agenda, Ellen MacArthur Foundation, Science Advances.",
        "readMoreModal_sources_slide1": "Quelle: High fashion! Mountain of discarded clothes in Chilean desert is visible from space (satellite photo), von Robert Lea, veröffentlicht am 22. Juni 2023"
    },
    [ScreensEnum.Statistics]: {
        'text1': "Diese Statistik wirft ein Licht auf die verbesserten Recycling- und Nachhaltigkeitspraktiken in der Modeindustrie.",
        'text2': 'Die Tatsache, dass <b>weniger als 1% der Textilabfälle zu neuer Kleidung recycelt werden,</b> verdeutlicht den <b>dringenden Bedarf an weiteren Verbesserungen</b> in diesem Bereich.',
        'text3': 'Es besteht zweifellos ein großer Bedarf, die Textilabfallpraktiken zu verbessern.',
        'text4': '<b>Was kann also getan werden, um die Situation zu verbessern?</b>',
        'button': 'Lass uns herausfinden!',
    },
    [ScreensEnum.ShareIdeasTexts]: {
        'text1': "Um Fortschritte zu erzielen und die Situation zu verbessern, müssen wir mehrere wichtige Schritte unternehmen. Glücklicherweise gibt es bereits viele Akteure, die auf einen positiven Wandel hinarbeiten.",
        'text2': 'So entwickelt das derzeit laufende, <b>von der EU finanzierte Projekt SCIRT aktiv neue Wege zur Herstellung und zum Verkauf von Kleidung aus recycelten Materialien.</b> Ziel ist es, den Kreislauf zu schließen und ein vollständig zirkuläres Modesystem zu schaffen, indem alte Kleidungsstücke gesammelt und für die Herstellung neuer Kleidungsstücke verwendet werden.',
        'text3': '<b>Durch Ihre Teilnahme an dieser Online-Erfahrung tragen Sie bereits zu diesen Fortschritten bei, indem Sie sich des heutigen Modesystems bewusster werden.</b>',
        'text4': 'Bevor wir schließen, haben wir noch eine Bitte. Bitte teilen Sie Ihre Ideen mit uns!',
    },
    [ScreensEnum.ShareIdeasQuestion]: {
        'question': 'Was würde Sie dazu ermutigen, Ihre Kleidung zu dem Geschäft oder der Marke zurückzubringen, bei der Sie sie gekauft haben, damit sie zu neuer Kleidung recycelt werden kann?',
        'placeholder': 'Bitte tragen Sie Ihre Antwort hier ein',
    },
    [ScreensEnum.RateUs]: {
        'text1': 'Vielen Dank für Ihre Ideen {name}! Wir werden sie aufgreifen. Herzlichen Glückwunsch, Sie haben nun alle Schritte dieser Online-Erfahrung durchlaufen! Bevor Sie gehen, möchten wir Sie, {name}, bitten, diese kurze Reise zu bewerten. Wir danken Ihnen!',
        'button': 'Zur Abschlussseite gehen',
    },
    [ScreensEnum.FinalScreen]: {
        'text1': "Wir danken Ihnen für Ihre wertvolle Zeit und Ihre Teilnahme! Wir hoffen, dass Sie viel über die Modeindustrie erfahren haben. Möchten Sie informiert und beteiligt bleiben? Folgen Sie dem SCIRT-Projekt in den sozialen Medien, um an unserer Sensibilisierungskampagne teilzunehmen, oder <a href='https://app.lgi-consulting.org/gdpr/index.php?v=36' target='_blank'>melden Sie sich für den SCIRT-Newsletter an!</a> Bleiben Sie in Kontakt und tragen Sie zu einem positiven Wandel in der Modewelt bei!",
        'text2': "Lassen Sie uns gemeinsam einen Unterschied in der Modeindustrie machen und auf eine nachhaltigere Zukunft hinarbeiten."
    }
};
