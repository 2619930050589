import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(`${_ctx.buttonClass} ${_ctx.colorClass} ${_ctx.selected ? 'active' : ''}`),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.click())),
    type: "button"
  }, [
    (_ctx.preicon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "mr-2 text-gray-500",
          style: {"font-size":"1.5rem","line-height":"1","vertical-align":"middle"},
          innerHTML: _ctx.preicon
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.rawtext ? _ctx.rawtext : _ctx.$t(_ctx.text, {name: _ctx.state.nickname.value})), 1)
  ], 2))
}