
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Slider',
  emits: ['change'],
  props: {
    min: {
      type: Number,
      default: 1,
    },
    max: Number,
    step: {
      type: Number,
      default: 1,
    },
    unit: String,
    options: Object,
    value: String,
    alternate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      listId: Math.random().toString(36).substring(7),
      sliderValue: 1,
      selectedValueIndex: 0,
    }
  },

  computed: {
    list() {
      const dataList = [];

      if (this.options) {
        // make list of options
        dataList.push(...Object.entries(this.options).map(([index, option]) => {
          return {
            id: index,
            name: `${this.$t(option)} ${this.unit}`
          }
        }))
      } else {
        // make list of range
        for (let i = this.min; i <= this.max; i += this.step) {
          dataList.push({
            id: i,
            name: `${i} ${this.unit}`
          })
        }
      }

      return dataList;
    }
  },

  mounted() {
    let selectedValueIndex = this.list.findIndex(option => option.id == this.value);

    if (!selectedValueIndex || selectedValueIndex < 0) {
      selectedValueIndex = Math.floor(this.list.length / 2);
    }

    console.log('selectedValueIndex', selectedValueIndex);

    this.selectedValueIndex = selectedValueIndex;
    this.sliderValue = this.selectedValueIndex * this.step + this.min;
  },
  methods: {
    change(index: number) {
      this.selectedValueIndex = index;
      this.sliderValue = this.selectedValueIndex * this.step + this.min;
      const value = this.list[index]?.id;

      console.log('change', index, value, this.sliderValue);
      this.$emit('change', value);
    }
  },
});
